import { parseISO, isWithinInterval, isBefore, format } from 'date-fns';

import { calcDaysUnderOptions, calcAnnualDaysUnderOptions } from '../../utilities/charts/days-under-chart-options';
import updateOverlayFromR2 from '../../utilities/overlayUpdateFns';
import { fetchFromAcis } from '../../utilities/acis-api';
import { TODAY } from '../../contexts/constants';

const refreshDaysUnderChartData = (temps, threshold, edate) => {
  const newChartData = {
    timeseries: {
      dates: [],
      current: []
    },
    yearlySeasons: {
      years: [],
      toDate: [],
      fullSeason: []
    }
  };

  const seasonStartDay = '11-01';
  const fullSeasonEndDay = '04-30';

  let seasonEndDay = fullSeasonEndDay;
  if (edate && typeof edate !== 'function') {
    seasonEndDay = typeof edate === 'string' ? edate.slice(5,10) : format(edate, 'MM-dd');
  }

  let idxYearStart = null;
  let lastSeasonEndIdx = 0;
  for (let i = 0; i < temps.length; i++) {
    const strDate = temps[i][0];
    
    if (strDate.slice(5) === seasonStartDay) {
      newChartData.yearlySeasons.years.push(parseInt(strDate.slice(0,4)) + 1);
      idxYearStart = i;
    }
    
    if (strDate.slice(5) === seasonEndDay) {
      newChartData.yearlySeasons.toDate.push(temps.slice(idxYearStart, i + 1).filter(([_, val]) => val < threshold).length);
      lastSeasonEndIdx = i;
    }

    if (strDate.slice(5) === fullSeasonEndDay) {
      newChartData.yearlySeasons.fullSeason.push(temps.slice(idxYearStart, i + 1).filter(([_, val]) => val < threshold).length);
    }
  }

  const currentSeasonData = temps.slice(idxYearStart, lastSeasonEndIdx > idxYearStart ? (lastSeasonEndIdx + 1) : -1);
  newChartData.yearlySeasons.years.push(parseInt(currentSeasonData[currentSeasonData.length - 1][0].slice(0,4)));
  newChartData.yearlySeasons.fullSeason.push(currentSeasonData.filter(([_, val]) => val < threshold).length);
  newChartData.timeseries = currentSeasonData.reduce((acc, [date, value]) => {
    acc.dates.push(date);
    acc.current.push(value);
    return acc;
  }, { dates: [], current: [] });

  newChartData.yearlySeasons.fullSeason.slice(-31);
  newChartData.yearlySeasons.toDate.slice(-31);
  newChartData.yearlySeasons.years.slice(-31);

  return { newChartData, newCurrentSeasonChartData: null };
};

const TAB_NAME = 'Days Under';
const info = {
  name: TAB_NAME,
  subTabs: [
    {
      name: '32°F'
    },{
      name: '0°F'
    },
  ],
  chartInfo: {
    chartSelectorId: 'daysUnderChartSelector',
    charts: [{
      name: 'Days Under and Threshold Exceedance',
      func: (chartData, address, tabInfoContextState) => {
        const thresholdValue = tabInfoContextState.tabsSharedState.daysUnderThresholdSelector.value;
        let threshold = typeof thresholdValue === 'function' ? thresholdValue({selectedSubTab: tabInfoContextState.selectedSubTab})[0] : thresholdValue[0];
        return calcDaysUnderOptions(chartData, address, threshold);
      }
    },{
      name: 'Annual Number of Occurences',
      func: calcAnnualDaysUnderOptions
    }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: ({ selectedSubTab }, _, { updateOverlayData }) => {
      const jsonFileName = `days_under_${selectedSubTab.split('°')[0]}.json`;
      const jsonFileDataKey = 'days_under_departures';
      return updateOverlayFromR2(jsonFileName, jsonFileDataKey)
        .then(d => () => updateOverlayData(d));
    },
    showLoading: true
  },{
    whenXChanges: ['selectedLocation', 'selectedTab'],
    handleChange: ({ selectedTab, selectedLocation, pastLocations, overlayDataDate, rawChartData }, _, { setRawChartData }) => {
      if (selectedTab === TAB_NAME) {
        let newRawChartData = { ...rawChartData };
        if (!(selectedLocation in newRawChartData)) {
          newRawChartData = { [selectedLocation]: {} };
        }
        
        if (!(selectedTab in newRawChartData[selectedLocation])) {
          const locInfo = pastLocations[selectedLocation];
          const coords = `${locInfo.lng},${locInfo.lat}`;
          const dateObj = overlayDataDate ? parseISO(overlayDataDate) : TODAY;
          const startYear = dateObj.getFullYear() - (isBefore(dateObj, new Date(dateObj.year, 6, 1)) ? 1 : 0) - 31;

          const elems = {
            loc: coords,
            grid: 'prism',
            sdate: `${startYear}-11-01`,
            edate: format(dateObj, 'yyyy-MM-dd'),
            elems: [{ name: 'mint' }],
          };

          return fetchFromAcis(elems)
            .then(rawData => {
              newRawChartData[selectedLocation][selectedTab] = rawData;
              return () => setRawChartData(newRawChartData);
            });
        }
      }
      
      return () => {};
    },
    showLoading: true
  },{
    whenXChanges: ['daysUnderThresholdSelector', 'daysUnderEndDateSelector', 'rawChartData', 'selectedSubTab'],
    handleChange: ({ selectedTab, selectedSubTab, selectedLocation, tabsSharedState, rawChartData }, _, { setChartData }) => {
      if (
        selectedTab === TAB_NAME &&
        selectedLocation in rawChartData && selectedTab in rawChartData[selectedLocation]
      ) {
        let arg2 = tabsSharedState.daysUnderThresholdSelector.value;
        if (typeof arg2 === 'function') arg2 = arg2({selectedSubTab})[0];
        let arg3 = tabsSharedState.daysUnderEndDateSelector.value;
        if (typeof arg3 === 'function') arg3 = arg3();
        const { newChartData } = refreshDaysUnderChartData(rawChartData[selectedLocation][selectedTab], arg2, arg3);
        return () => setChartData(newChartData);
      }
      return () => {};
    },
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'days-under-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'days-under-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'days-under-charts',
      useSharedState: 'daysUnderChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'thresholdSelector',
      id: 'days-under-threshold-selector',
      useSharedState: 'daysUnderThresholdSelector',
      props: {
        columnHeading: 'Days Under',
        minNum: 1,
        maxNum: 1,
        minValue: -200,
        maxValue: 200,
        step: 1,
        colors: ['black'],
        label: () => 'Change Threshold',
        showReset: true
      }
    },{
      component: 'dateSelector',
      id: 'days-under-edate-selector',
      useSharedState: 'daysUnderEndDateSelector',
      props: {
        label: 'Season End Date',
        pickerLabel: 'Only affects Days Under charts',
        minDate: (today) => {
          const todayDate = parseISO(today);

          // Set minDate to 11/1 of last year if before 11/1, otherwise this year
          const yearShift = isBefore(todayDate, new Date(todayDate.getFullYear(), 10, 1)) ? 1 : 0;
          return new Date(todayDate.getFullYear() - yearShift, 10, 1);
        },
        maxDate: (today) => {
          const todayDate = parseISO(today);
          
          // Set maxDate to today unless is it 5/1 - 10/31, then use 4/30
          return isWithinInterval(todayDate, {
            start: new Date(todayDate.getFullYear(), 4, 1),
            end: new Date(todayDate.getFullYear(), 9, 31)
          }) ? new Date(todayDate.getFullYear(), 3, 30) : todayDate;
        }
      }
    }]
  },
  componentFns: {
    title: ({selectedSubTab, display, overlayDataDate, tabsSharedState}) => {
      try {
        let dateObj;

        console.log(display);

        if (display === 'map') {
          dateObj = parseISO(overlayDataDate)
        } else {
          const selectorValue = tabsSharedState.daysUnderEndDateSelector.value;
          dateObj = parseISO(typeof selectorValue === 'function' ? selectorValue() : selectorValue);
        }
        
        const syear = dateObj.getFullYear() - (isBefore(dateObj, new Date(dateObj.getFullYear(), 10, 1)) ? 1 : 0);
        const sdate = format(new Date(syear, 10, 1), 'LLLL do, yyyy');
        
        const edateObj = isWithinInterval(dateObj, {
          start: new Date(dateObj.getFullYear(), 4, 1),
          end: new Date(dateObj.getFullYear(), 9, 31)
        }) ? parseISO(`${dateObj.getFullYear()}-04-30`) : dateObj;
        const edate = format(edateObj, 'LLLL do, yyyy');

        console.log(`Days Under ${selectedSubTab}: ${sdate} - ${edate}`);
    
        return `Days Under ${selectedSubTab}: ${sdate} - ${edate}`;
      } catch {
        return '';
      }
    }
  },
  textPanelInfo: [{
    name: 'How To Use Tool',
    markdownFilename: 'how-to-use_default.md'
  },{
    name: 'About The Data',
    markdownFilename: 'about-the-data_default.md'
  }]
};
export default info;

const chartNames = info.chartInfo.charts.map(obj => obj.name);
export { chartNames as daysUnderCharts };
import { formatISO, isWithinInterval } from "date-fns";

import { TODAY, regionNames } from "../../contexts/constants";

import { daysUnderCharts } from "./days-under";

const sharedStateDefinitions = {
  'regionSelector': {
    options: regionNames,
    initValue: regionNames,
    props: {
      listTypeSingular: 'Region',
      listTypePlural: 'Regions',
    }
  },
  'departureSelector': {
    options: ['Last Year', 'Normal'],
    initValue: 'Last Year'
  },
  'daysUnderChartSelector': {
    options: daysUnderCharts,
    initValue: daysUnderCharts
  },
  'daysUnderThresholdSelector': {
    onNavChange: (_, st, oldValue) => {
      const newValue = parseInt(st.split('°')[0]);
      return isNaN(newValue) ? oldValue : [newValue];
    },
    initValue: ({ selectedSubTab }) => ([parseInt(selectedSubTab.split('°')[0])])
  },
  'daysUnderEndDateSelector': {
    persist: false,
    initValue: () => {
      // Set maxDate to today unless is it 5/1 - 10/31, then use 4/30
      return isWithinInterval(TODAY, {
        start: new Date(TODAY.getFullYear(), 4, 1),
        end: new Date(TODAY.getFullYear(), 9, 31)
      }) ? `${TODAY.getFullYear()}-04-30` : formatISO(TODAY);
    }
  },
};

export default sharedStateDefinitions;
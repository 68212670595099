import React, { useState } from 'react';

import {
  Box,
  Drawer,
  IconButton
} from '@mui/material';

import Accordion from '../accordion/accordion.component';
import { ReactComponent as MenuIcon } from './menu.svg';

export default function NavDrawer({ navInfo, selectedGroup, selectedOption, handleSelect }) {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(selectedGroup);

  const toggleDrawer = (event) => {
    if (
      event.type === 'keydown' &&
      ((event).key === 'Tab' || (event).key === 'Shift')
    ) {
      return;
    }

    setOpen(!open);
  };

  const handleExpandGroup = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSelectOption = (newGroup, newOption) => {
    if (newOption !== selectedOption) {
      handleSelect(newGroup, newOption);
      toggleDrawer({ type: 'func' });
    }
  }

  return (
    <Box>
      <IconButton
        sx={{
          height: '26px',
          width: '28px',
          backgroundColor: 'black',
          borderRadius: '6px',
          margin: '3px 0 0 6px',
          padding: 0,
          '&:hover': {
            backgroundColor: 'rgb(50,50,50)',
          },
        }}
        onClick={() => setOpen(!open)}
      >
        <MenuIcon fill='white' />
      </IconButton>

      <Drawer
        anchor='left'
        open={open}
        onClose={toggleDrawer}
        PaperProps={{
          style: {
            backgroundColor: 'rgb(240,240,240)',
            width: 300,
            paddingTop: 20,
            paddingBottom: 20,
            boxSizing: 'border-box',
          },
        }}
      >
        <Box role='presentation' onKeyDown={toggleDrawer}>
          {navInfo.map(({name, subTabs}, i) => 
            subTabs.length ?
              <Accordion key={name} expanded={expanded === name} handleChange={handleExpandGroup} id={name} label={name} className={'nav-link-group' + (selectedGroup === name ? ' selected-group' : '')} arrowColor={selectedGroup === name ? 'white' : 'black'}>
                {subTabs.map(subTab =>
                  <button key={subTab} className={'nav-link-option' + (selectedOption === subTab ? ' selected-option' : '')} onClick={() => handleSelectOption(name, subTab)}>{subTab}</button>
                )}
              </Accordion>
              :
              <button
                key={name}
                className={'nav-link-group nav-drawer-no-subtabs' + (selectedGroup === name ? ' selected-group' : '')}
                style={{ borderBottom: i + 1 === navInfo.length ? '1px solid black' : 'none' }}
                onClick={() => handleSelectOption(name, 'none')}
              >
                {name}
              </button>
          )}
        </Box>
      </Drawer>
    </Box>
  );
}

import React, { useContext } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { TabInfoContext } from '../../contexts/tab-info.context';
import { GlobalContext } from '../../contexts/global.context';

import Chart from './chart/chart.component';

import './charts.styles.scss';

export default function Charts() {
  const tabInfoContextState = useContext(TabInfoContext);
  const { charts, chartSelectorId } = tabInfoContextState.chartInfo;
  const { chartData, selectedSubTab, selectedLocation, getSelectorValueByIdFromTabInformation } = useContext(GlobalContext);

  const { width, height, ref } = useResizeDetector();

  let chartsArray = [];
  if (charts && chartSelectorId) {
    const selectedCharts = getSelectorValueByIdFromTabInformation(chartSelectorId);
    chartsArray = charts.filter(({ name }) => selectedCharts.includes(name));
  }

  return (
    <div className='charts-container' ref={ref}>
      {chartsArray.map(({ func }, i) => {
        let options, key;
        try {
          options = func(chartData, selectedLocation.address, {...tabInfoContextState, selectedSubTab});
          key = options.title.text;
        } catch {
          options = {};
          key = new Date().getTime();
        }
        return (
          <React.Fragment key={key + String(i)}>
            {i !== 0 && <div className='hr' />}
            <Chart
              options={options}
              width={width}
              height={height / 2 - 10 || 200}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}

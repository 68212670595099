import React, { useState, useContext, useEffect } from 'react';
import {
  Tabs,
  Tab,
  Box,
  useMediaQuery
} from '@mui/material';


import { TabInfoContext } from '../../contexts/tab-info.context';

import MarkdownToJsx from '../markdown-to-jsx/markdown-to-jsx.component';

import './text-panel.styles.scss';


function a11yProps(idx) {
  return {
    id: `vertical-tab-${idx}`,
    'aria-controls': `vertical-tabpanel-${idx}`,
  };
}

export default function TextPanel() {
  const { textPanelInfo } = useContext(TabInfoContext);

  const [tabIdx, setTabIdx] = useState(0);

  useEffect(() => {
    setTabIdx(0);
  }, [textPanelInfo]);

  const isSmallScreen = useMediaQuery('(max-width:550px)');

  const handleTabChange = (e, newTab) => {
    setTabIdx(newTab);
  };

  if (textPanelInfo.length === 0 || tabIdx >= textPanelInfo.length ) {
    return '';
  } else {
    return (
      <Box className='text-panel'>
        <Box
          sx={{ flexGrow: 1, display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row' }}
        >
          <Tabs
            orientation={isSmallScreen ? 'horizontal' : 'vertical'}
            variant={isSmallScreen ? 'scrollable' : 'standard'}
            scrollButtons={isSmallScreen}
            allowScrollButtonsMobile={isSmallScreen}
            value={tabIdx}
            onChange={handleTabChange}
            aria-label="Tabs controlling text content"
            sx={{ border: 1, borderColor: 'divider', minWidth: isSmallScreen ? 'unset' : 'fit-content', height: 'fit-content' }}
          >
            {textPanelInfo.map(({ name }, idx) => <Tab label={name} key={'tab-' + name} {...a11yProps(idx)} sx={{ padding: '12px 8px' }} />)}
          </Tabs>
  
          <Box
            role="tabpanel"
            id={`vertical-tabpanel-${tabIdx}`}
            aria-labelledby={`vertical-tab-${tabIdx}`}
            className='text-panel-md-content'
          >
            <MarkdownToJsx
              path={`${process.env.PUBLIC_URL}/markdown/${textPanelInfo[tabIdx].markdownFilename}`}
            />
          </Box>
        </Box>
      </Box>
    );
  }
}
const defaultBinColors = [
  'rgb(0.0,0.0,127.5)',
  'rgb(0.0,0.0,232.0)',
  'rgb(0.0,56.5,255.0)',
  'rgb(0.0,148.5,255.0)',
  'rgb(12.1,244.5,234.7)',
  'rgb(86.3,255.0,160.5)',
  'rgb(160.5,255.0,86.3)',
  'rgb(234.7,255.0,12.1)',
  'rgb(255.0,170.6,0.0)',
  'rgb(255.0,85.4,0.0)',
  'rgb(232.0,0.2,0.0)',
  'rgb(127.5,0.0,0.0)',
];

export async function fetchPrecalculatedOverlayDataFromBucket(jsonFileName, dataKey) {
  // Fetch data from R2 bucket

  const response = await fetch(
    // `/data/${jsonFileName}`,
    `/overlays/${jsonFileName}`,
    { headers: { 'Authorization': `Bearer ${process.env.REACT_APP_BUCKET_AUTH}` } }
  );

  if (response.ok) {
    // Parse to JSON
    const data = await response.json();

    // If valid data, extract what is needed
    if (data && Object.keys(data).length) {
      return { data: data[dataKey], dataDate: data['data_date'] };
    }
  }

  // Default to null data values
  return { data: null, dataDate: null };
}

export function convertToColors(overlayData, binColors, binThresholds) {
  const colors = {};
  const usedGeoids = [];
  const lastBinId = binColors.length - 1;

  for (const [region, regionObj] of Object.entries(overlayData)) {
    colors[region] = [];
    for (const [geoid, percentage] of Object.entries(regionObj)) {
      if (usedGeoids.includes(geoid)) continue;
      for (let i = 0; i < binThresholds.length; i++) {
        const threshold = binThresholds[i];
        if (percentage <= threshold || i === lastBinId) {
          colors[region].push(geoid, binColors[lastBinId - i]);
          usedGeoids.push(geoid);
          break;
        }
      }
    }
  }

  return colors;
};

export default function updateOverlayFromR2(jsonFileName, dataKey, initBinColors=defaultBinColors,  isReversed=false, thresholdFn=(binIdx) => ((binIdx - 5) * 10)) {
  return fetchPrecalculatedOverlayDataFromBucket(jsonFileName, dataKey)
    .then(({ data, dataDate }) => {
      const colors = {};
      const binThresholds = initBinColors.map((_,i) => thresholdFn(i));
      let binColors = [...initBinColors];
      if (isReversed) {
        binColors.reverse();
      }
      
      for (const [departureName, departureData] of Object.entries(data)) {
        if (departureName && departureData) {
          colors[departureName] = convertToColors(departureData, binColors, binThresholds);
        }
      }
      
      binThresholds.reverse();
      return { data, dataDate, colors, binColors, binThresholds: binThresholds.slice(1) };
    });
}
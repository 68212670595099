import React from 'react';
import {
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';

import Accordion from '../accordion/accordion.component';

const defaultProps = {
  options: [],
  showAll: true,
  showNone: true,
  value: [],
  listTypeSingular: 'Item',
  listTypePlural: 'Items'
};

export default function ListSelector({
  expanded,
  handleChange,
  handleChangeSelection,
  id=null,
  options=defaultProps.options,
  showAll=defaultProps.showAll,
  showNone=defaultProps.showNone,
  value=defaultProps.value,
  listTypeSingular=defaultProps.listTypeSingular,
  listTypePlural=defaultProps.listTypePlural,
}) {
  if (options.length === 0) {
    return <div>No options provided to selector.</div>;
  } else if (id === null) {
    return <div>Must provide id to component.</div>;
  }

  const label = value.length === 0 ? `Select ${listTypePlural}` : `Showing ${value.length} ${value.length === 1 ? listTypeSingular : listTypePlural}`;

  return (
    <Accordion expanded={expanded === id} handleChange={handleChange} id={id} label={label} >
      <div className='list-selector-container'>
        <div className='list-selector-title'>Select {listTypePlural}</div>
        <FormGroup>
          {showAll ?
            <FormControlLabel
              control={
                <Checkbox
                  checked={value.length === options.length}
                  onChange={() => handleChangeSelection(options)}
                />
              }
              label='All'
            />
            :
            ''
          }

          {options.map((itemName) => (
            <FormControlLabel
              key={itemName}
              control={
                <Checkbox
                  checked={value.includes(itemName)}
                  onChange={() => {
                    if (value.includes(itemName)) {
                      handleChangeSelection(value.filter((r) => r !== itemName));
                    } else {
                      handleChangeSelection([...value, itemName]);
                    }
                    
                  }}
                />
              }
              label={itemName}
            />
          ))}

          {showNone ?
            <FormControlLabel
              control={
                <Checkbox
                  checked={value.length === 0}
                  onChange={() => handleChangeSelection([])}
                />
              }
              label='None'
            />
            :
            ''
          }
        </FormGroup>
      </div>
    </Accordion>
  );
}

export { defaultProps };
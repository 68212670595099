import React from 'react';

import './header.styles.scss';

export default function Header() {
  return (
    <header>
      <div className='angled angle-1'></div>
      <div className='angled angle-2'></div>

      <h1>Forest Application</h1>
      <h3>Forest Related Observations Across the US</h3>
    </header>
  );
}